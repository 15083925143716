import React from 'react'
import Layout from '../components/layout'

const privacy = () => {
  return (
    <Layout>
      <div className='relative py-16 bg-white overflow-hidden'>
        <div className='relative px-4 sm:px-6 lg:px-8'>
          <div className='text-lg max-w-prose mx-auto'>
            <h1 className='block  text-center text-customBlue font-semibold tracking-wide uppercase'>
            TRUSTLOKR
            Privacy Policy
            </h1>
            <span className='block  text-center'>
              Last Updated  December 22, 2023
            </span>
            <br />
            <p>
              TrustLoKR LLC (“TrustLoKR”) collects, uses, and discloses
              information about you. The collection, use and disclosure of this
              information is essential to the business of TrustLoKR. Indeed,
              TrustLoKR’s primary purposes include the collection and disclosure
              of pertinent financial, legal and health information. This Privacy
              Policy applies to the services and products (collectively, the
              “Service”) provided by TrustLoKR through TrustLoKR’s website,
              mobile application, and any other communication with TrustLoKR.
            </p>
            <p>
              TrustLoKR may change this Privacy Policy from time to time. If
              TrustLoKR makes changes, we will notify you by revising the date
              at the top of this policy. Please feel free to check this privacy
              policy as often as you like. From time to time, TrustLoKR may
              provide an additional notice through a notification by email, text
              or otherwise.
            </p>
            <h2>Our Service- Your Information</h2>
            <p>
              When you create an account, we ask that you provide your name,
              email address, and other personal information. Our Service is a
              tool to provide you and those who you designate, access to
              pertinent financial, legal and health documents and information.
              As such, the use of the Service may include, among other things,
              providing information relating to assets, liabilities, legal
              documents and healthcare information. In this process, we may
              collect this information, and similar information, to enhance your
              use of the Service. We also collect information as you use the
              Service to share your information with others, fill out
              documentation regarding contact information of others, upload
              documents, upload or input information, request customer support
              or otherwise provide information or communicate with TrustLoKR.
            </p>
            <p>
              Please know that when you access or use our Service, we
              automatically collect certain information, including:
            </p>
            <h3>Transactional Information</h3>
            <p>
              When you make a purchase (including paying subscription fees), we
              collect information about the transaction, such as purchase price,
              and date and location of the transaction.
            </p>

            <h3>Device and Usage Information</h3>
            <p>
              We collect information about how you access our Service, including
              data about the device and network you use, such as your hardware
              model, operating system version, mobile network, IP address,
              unique device identifiers, browser type, and app version. We also
              collect information about your activity on our Service, such as
              access times, pages viewed, links clicked, and the page you
              visited before navigating to our Service.
            </p>

            <h3>Location Information</h3>
            <p>
              We may derive the approximate location of your device from your IP
              address. In accordance with your device permissions, we may also
              collect information about the precise location of your device. You
              have the ability to stop the collection of precise location
              information at any time (see Your Choices below for details)
            </p>

            <h3>
              Information Collected by Cookies and Similar Tracking Technologies
            </h3>
            <h3>Information We Collect from Other Sources</h3>
            <p>
              We obtain information from other sources. For example, we may
              collect information about you from third parties, including but
              not limited to publicly available sources.
            </p>

            <h3>Information We Derive</h3>
            <p>
              We may derive information or draw inferences about you based on
              the information we collect. For example, we may make inferences
              about your location based on your IP address.
            </p>

            <h3>Use of Your Information</h3>
            <p>We use the information we collect to:</p>

            <p>Provide, maintain, and improve our Service; </p>

            <p>Personalize and improve your experience on our Service;</p>

            <p>
              Send you technical notices, security alerts, and support and
              administrative messages;
            </p>

            <p>
              Respond to your comments and questions and provide customer
              service;
            </p>

            <p>
              Communicate with you about products, services, and events offered
              by TrustLoKR and others and provide news and information that we
              think will interest you (see the Your Choices section below for
              information about how to opt-out of these communications at any
              time);
            </p>

            <p>
              Monitor and analyze trends, usage, and activities in connection
              with our Service;
            </p>

            <p>
              Personalize the advertisements you see on third-party platforms
              and websites (for more information, see the Advertising and
              Analytics section below);
            </p>

            <p>
              Personalize the advertisements you see when you use our Service
              based on information provided by our advertising partners;
            </p>

            <p>
              Facilitate contests, sweepstakes, and promotions and process and
              deliver entries and rewards
            </p>

            <p>
              Detect, investigate, and prevent security incidents and other
              malicious, deceptive, fraudulent, or illegal activity and protect
              the rights and property of TrustLoKR and others;
            </p>

            <p>Debug to identify and repair errors on our Service; </p>

            <p>Comply with our legal and financial obligations; and</p>

            <p>
              Carry out any other purpose described to you at the time the
              information was collected.
            </p>

            <h3>Sharing of Information</h3>
            <p>
              We share personal information in the following circumstances or as
              otherwise described in this Privacy Policy:
            </p>

            <p>
              We share personal information with vendors, service providers, and
              consultants that need access to personal information in order to
              perform services for us, such as companies that assist us with web
              hosting, videoconferencing, fraud prevention, customer service,
              and marketing and advertising.
            </p>

            <p>
              We share personal information with other users of our Service when
              you make content available in publicly-accessible areas of our
              Service.
            </p>

            <p>
              We may disclose personal information if we believe that disclosure
              is in accordance with, or required by, any applicable law or legal
              process, including lawful requests by public authorities to meet
              national security or law enforcement requirements.
            </p>

            <p>
              We may share personal information if we believe that your actions
              are inconsistent with our user agreements or policies, if we
              believe that you have violated the law, or if we believe it is
              necessary to protect the rights, property, and safety of us, our
              users, the public, or others.
            </p>

            <p>
              We share personal information with our lawyers and other
              professional advisors where necessary to obtain advice or
              otherwise protect and manage our business interests.
            </p>

            <p>
              We may share personal information in connection with, or during
              negotiations concerning, any merger, sale of company assets,
              financing, or acquisition of all or a portion of our business by
              another company.
            </p>

            <p>
              We share personal information with your consent or at your
              direction.
            </p>

            <p>
              We also share aggregated or de-identified information that cannot
              reasonably be used to identify you.
            </p>

            <h3>Social Sharing Features</h3>
            <p>
              Our Service may offer social sharing features and other integrated
              tools (such as the Facebook &ldquo;Like&rdquo; button). Your use
              of such features enables the sharing of information with your
              friends or the public, depending on the settings you establish
              with the entity that provides the social sharing feature. For more
              information about the purpose and scope of data collection and
              processing in connection with social sharing features, please
              visit the privacy policies of the entities that provide these
              features.
            </p>

            <h3>Advertising and Analytics</h3>
            <p>
              We allow others to provide analytics services and serve
              advertisements on our behalf across the web and in mobile apps.
              These entities may use cookies, web beacons, device identifiers,
              and other technologies to collect information about your use of
              our Service and other websites and applications, including your IP
              address, web browser, mobile network information, pages viewed,
              time spent on pages or in mobile apps, links clicked, and
              conversion information. This information may be used by us and
              others to, among other things, analyze and track data, determine
              the popularity of certain content, deliver advertising and content
              targeted to your interests on our Service and other websites], and
              better understand your online activity. [For more information
              about interest-based ads, or to opt out of having your web
              browsing information used for behavioral advertising purposes,
              please visit www.aboutads.info/choices.]
            </p>

            <h3>Data Retention</h3>
            <p>
              We store the information we collect on you for as long as is
              necessary for the purpose(s) for which we originally collected it,
              or for other legitimate business purposes, including to meet our
              legal, regulatory, or other compliance obligations.
            </p>

            <h3>
              Transfer of Information To the United States and Other Countries
            </h3>
            <p>
              TrustLoKR is headquartered in the United States, and we have
              operations and service providers in the United States. Therefore,
              we and our service providers may transfer your personal
              information to, or store or access it in, jurisdictions that may
              not provide levels of data protection that are equivalent to those
              of your home jurisdiction. We will take steps to ensure that your
              personal information receives an adequate level of protection in
              the jurisdictions in which we process it.
            </p>

            <h2>Your Choices</h2>
            <h3>Account Information</h3>
            <p>
              You may update and correct certain account information you provide
              to us at any time by logging into your account, emailing us at
              <a href='mailto:contact@trustlokr.com'>contact@trustlokr.com</a>.
              If you wish to delete or deactivate your account you may do so at
              any time by emailing
              <a href='mailto:contact@trustlokr.com'>contact@trustlokr.com</a>,
              but note that we may retain certain information as required by law
              or for legitimate business purposes. We may also retain cached or
              archived copies of information about you for a certain period of
              time.
            </p>

            <h3>Location Information</h3>
            <p>
              When you first launch any of our mobile applications that collect
              precise location information, you will be asked to consent to the
              application&rsquo;s collection of this information. If you
              initially consent to our collection of this location information,
              you can subsequently stop the collection of this information at
              any time by changing the preferences on your mobile device. If you
              do so, our mobile applications, or certain features thereof, may
              no longer function properly. You may also stop our collection of
              this location information by following the standard uninstall
              process to remove all of our mobile applications from your device.
            </p>

            <h3>Cookies</h3>
            <p>
              Most web browsers are set to accept cookies by default. If you
              prefer, you can usually adjust your browser settings to remove or
              reject browser cookies. Please note that removing or rejecting
              cookies could affect the availability and functionality of the
              Service.
            </p>

            <h3>Communications Preferences</h3>
            <p>
              You may opt-out of receiving promotional emails from TrustLoKR by
              following the instructions in those communications. If you
              opt-out, we may still send you non-promotional emails, such as
              those about your account or our ongoing business relations.
            </p>

            <h3>Mobile Push Notifications/Alerts</h3>
            <p>
              With your consent, we may send promotional and non-promotional
              push notifications or alerts to your mobile device. You can
              deactivate these messages at any time by changing the notification
              settings on your mobile device.
            </p>

            <h2>Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us at
              <a href='mailto:contact@trustlokr.com'> contact@trustlokr.com</a>.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default privacy
